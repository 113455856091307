import React, { CSSProperties, useCallback, useMemo } from 'react'
import { resetStripeOptions, selectSubscriptions } from '../slices/payment'
import { store, useAppSelector } from '../store'
import { formatStripeTimestamp } from '../utils'
import Amex from '../../assets/icons/Amex.png'
import ApplePay from '../../assets/icons/ApplePay.svg'
import CashApp from '../../assets/icons/CashApp.svg'
import Discover from '../../assets/icons/Discover.png'
import GooglePay from '../../assets/icons/GooglePay.svg'
import Mastercard from '../../assets/icons/Mastercard.svg'
import PayPal from '../../assets/icons/PayPal.png'
import Visa from '../../assets/icons/Visa.png'
import { PiBank } from 'react-icons/pi'
import Stripe from 'stripe'
import {
    PaymentIntentResult,
    PaymentMethodResult,
    StripeElements,
    Stripe as StripeJs,
} from '@stripe/stripe-js'
import { RPPaymentMethod } from '../entities'
import { colors, SubscriptionStatus } from '@hazadapt-git/public-core-base'
import { shallowEqual } from 'react-redux'
import { startOfMonth } from 'date-fns'

export type SubscriptionWithStatus = Omit<Stripe.Subscription, 'status'> & {
    status: SubscriptionStatus
}

export interface SimplifiedSeatSubscription {
    id: string
    status: SubscriptionStatus
    renewalDate: string | null
    purchaseDate: string | null
    cancelDate: string | null
    seatCount: number
    paymentMethod: string | null
}

const formatSubscriptionWithReadableStatus = (
    sub: Stripe.Subscription
): SubscriptionWithStatus => {
    let status: SubscriptionStatus
    if (
        sub.status === SubscriptionStatus.ACTIVE &&
        !!sub.cancel_at &&
        sub.cancellation_details?.reason === 'cancellation_requested'
    ) {
        status = SubscriptionStatus.PENDING_CANCELLATION
    } else if (sub.status === 'canceled') {
        status = SubscriptionStatus.CANCELLED
    } else {
        status = SubscriptionStatus.ACTIVE
    }
    return {
        ...sub,
        status,
    }
}

export const useBilling = () => {
    const { subscriptions, payment_methods, customer } = useAppSelector(
        selectSubscriptions,
        shallowEqual
    )

    // Root subscription
    const rootSubscription: SubscriptionWithStatus | null = useMemo(() => {
        const sub = subscriptions?.find(
            (sub) => sub.metadata.subscription_type === 'core'
        )
        if (!sub) return null
        return formatSubscriptionWithReadableStatus(sub)
    }, [subscriptions])

    const rootSubscriptionEndDate = useMemo(
        () =>
            rootSubscription
                ? formatStripeTimestamp(rootSubscription.current_period_end)
                : 'N/A',
        [rootSubscription]
    )

    const rootSubscriptionHumanReadableEndDate = useMemo(
        () =>
            rootSubscription
                ? formatStripeTimestamp(
                      rootSubscription.current_period_end,
                      true
                  )
                : 'N/A',
        [rootSubscription]
    )

    // Add-on subscriptions
    const addOnSubscriptions = useMemo(() => {
        const subs =
            subscriptions?.filter(
                (sub) =>
                    sub.metadata.subscription_type === 'add-on' &&
                    sub.metadata.status === SubscriptionStatus.ACTIVE
            ) ?? []
        return subs.map(formatSubscriptionWithReadableStatus)
    }, [subscriptions])

    // Seat subscriptions
    const seatSubscriptions = useMemo(() => {
        const subs =
            subscriptions?.filter((sub) =>
                sub.items.data.find(
                    (item) => item.metadata.add_on_type === 'seat'
                )
            ) ?? []
        return subs.map(formatSubscriptionWithReadableStatus)
    }, [subscriptions])

    const simplifiedSeatSubscriptions: SimplifiedSeatSubscription[] =
        useMemo(() => {
            return (
                seatSubscriptions
                    ?.map((sub) => {
                        const seatItem = sub.items.data.find(
                            (item) => item.metadata.add_on_type === 'seat'
                        )

                        return {
                            id: sub.id,
                            status: sub.status,
                            renewalDate: formatStripeTimestamp(
                                sub.current_period_end
                            ),
                            purchaseDate: formatStripeTimestamp(
                                sub.current_period_start
                            ),
                            cancelDate: formatStripeTimestamp(
                                sub.ended_at ?? sub.cancel_at
                            ),
                            seatCount: seatItem?.quantity ?? 0,
                            paymentMethod:
                                sub.default_payment_method?.toString() ?? null,
                        }
                    })
                    .filter((item) => item.seatCount > 0) || []
            )
        }, [seatSubscriptions])

    const unresolvedSeatCount = useMemo(() => {
        let seatCount = 0
        if (seatSubscriptions) {
            for (const sub of seatSubscriptions) {
                if (sub.metadata.status === 'cancelled') {
                    const unresolved = sub?.metadata.unresolved
                    if (unresolved && unresolved !== '{}') {
                        try {
                            const parsed = JSON.parse(unresolved)
                            if (parsed.seats > 0) {
                                seatCount += parsed.seats
                            }
                        } catch (err) {
                            console.error('Error parsing unresolved data:', err)
                        }
                    }
                }
            }
        }
        return seatCount
    }, [seatSubscriptions])

    // Payment method management
    const getPaymentMethodType = useCallback((pm: Stripe.PaymentMethod) => {
        switch (pm.type) {
            case 'card':
                //Google/Apple Pay are still card payments and include a wallet object
                if (pm.card?.wallet) {
                    if (pm.card?.wallet.type === 'apple_pay') {
                        return 'Apple Pay'
                    } else if (pm.card?.wallet.type === 'google_pay')
                        return 'Google Pay'
                }
                return pm.card?.funding === 'debit'
                    ? 'Debit Card'
                    : 'Credit Card'
            case 'cashapp':
                return 'Cash App Pay'
            case 'paypal':
                return 'PayPal'
            case 'us_bank_account':
                return 'Bank Account'
            default:
                return ''
        }
    }, [])

    const getPaymentMethodLast4 = useCallback((pm: Stripe.PaymentMethod) => {
        if (pm.card) {
            return pm.card.last4
        } else if (pm.us_bank_account) {
            return pm.us_bank_account.last4
        }
        return null
    }, [])

    const getPaymentMethodExpirationDateString = useCallback(
        (pm: Stripe.PaymentMethod) => {
            if (pm.type !== 'card' || !pm.card) return null
            return `${pm.card.exp_month.toString().padStart(2, '0')}/${
                pm.card.exp_year
            }`
        },
        []
    )

    const getPaymentMethodExpirationDate = useCallback(
        (pm: Stripe.PaymentMethod) => {
            if (pm.type !== 'card' || !pm.card) return null
            const expirationDate = new Date()
            expirationDate.setFullYear(pm.card.exp_year, pm.card.exp_month - 1)
            return startOfMonth(expirationDate)
        },
        []
    )

    const getPaymentMethodIcon = useCallback(
        (
            pm: Stripe.PaymentMethod,
            paymentMethodType: string | null
        ): React.ReactNode => {
            if (!paymentMethodType) return null
            const iconDims: CSSProperties = {
                width: '4rem',
                height: 'auto',
            }

            switch (paymentMethodType) {
                case 'Apple Pay':
                    return (
                        <img src={ApplePay} alt="Apple Pay" style={iconDims} />
                    )
                case 'Google Pay':
                    return (
                        <img
                            src={GooglePay}
                            alt="Google Pay"
                            style={iconDims}
                        />
                    )
                case 'Bank Account':
                    return <PiBank size="4rem" color={colors.grays.CHARCOAL} />
                case 'Cash App Pay':
                    return <img src={CashApp} alt="Cash App" style={iconDims} />
                case 'PayPal':
                    return <img src={PayPal} alt="PayPal" style={iconDims} />
                case 'Debit Card':
                case 'Credit Card':
                    switch (pm.card?.brand) {
                        case 'visa':
                            return (
                                <img src={Visa} alt="Visa" style={iconDims} />
                            )
                        case 'mastercard':
                            return (
                                <img
                                    src={Mastercard}
                                    alt="Mastercard"
                                    style={iconDims}
                                />
                            )
                        case 'amex':
                            return (
                                <img
                                    src={Amex}
                                    alt="American Express"
                                    style={iconDims}
                                />
                            )
                        case 'discover':
                            return (
                                <img
                                    src={Discover}
                                    alt="Discover"
                                    style={iconDims}
                                />
                            )
                        default:
                            return null
                    }
                default:
                    return null
            }
        },
        []
    )

    const paymentMethods = useMemo(() => {
        const methods: RPPaymentMethod[] = []
        const defaultPaymentMethod = customer?.invoice_settings
            ?.default_payment_method as Stripe.PaymentMethod | null
        for (const pm of payment_methods) {
            const paymentMethodType = getPaymentMethodType(pm)
            const last4 = getPaymentMethodLast4(pm)
            const expirationDateStr = getPaymentMethodExpirationDateString(pm)
            const expirationDateObj = getPaymentMethodExpirationDate(pm)
            methods.push({
                repr: pm,
                id: pm.id,
                type: paymentMethodType,
                last4,
                expirationDate: {
                    str: expirationDateStr,
                    obj: expirationDateObj,
                },
                icon: getPaymentMethodIcon(pm, paymentMethodType),
                date_created: pm.created,
                default: pm.id === defaultPaymentMethod?.id,
            })
        }
        methods.sort((a, b) => {
            if (a.default) return -1
            if (b.default) return 1
            return b.date_created - a.date_created
        })
        return methods
    }, [
        getPaymentMethodIcon,
        getPaymentMethodLast4,
        getPaymentMethodExpirationDateString,
        getPaymentMethodExpirationDate,
        getPaymentMethodType,
        payment_methods,
        customer,
    ])

    const createPaymentMethod = useCallback(
        async ({
            stripe,
            clientSecret,
            elements,
            returnUrl,
        }: {
            stripe: StripeJs
            clientSecret: string
            elements: StripeElements
            returnUrl: string
        }): Promise<PaymentMethodResult> => {
            const result = await stripe.confirmSetup({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: returnUrl,
                },
            })
            return result
        },
        []
    )

    const confirmPayment = useCallback(
        async ({
            stripe,
            clientSecret,
            elements,
            selectedPaymentMethod,
            returnUrl,
        }: {
            stripe: StripeJs
            clientSecret: string
            elements?: StripeElements
            selectedPaymentMethod: Stripe.PaymentMethod | null
            returnUrl: string
        }): Promise<PaymentIntentResult> => {
            let result: PaymentIntentResult
            if (!selectedPaymentMethod) {
                result = await stripe.confirmPayment({
                    //`Elements` instance that was used to create the Payment Element
                    elements,
                    clientSecret,
                    confirmParams: {
                        return_url: returnUrl,
                    },
                })
            } else {
                switch (selectedPaymentMethod.type) {
                    case 'card': {
                        result = await stripe.confirmCardPayment(clientSecret, {
                            payment_method: selectedPaymentMethod.id,
                            return_url: returnUrl,
                        })
                        break
                    }
                    case 'cashapp': {
                        result = await stripe.confirmCashappPayment(
                            clientSecret,
                            {
                                payment_method: selectedPaymentMethod.id,
                                return_url: returnUrl,
                            }
                        )
                        break
                    }
                    case 'paypal': {
                        result = await stripe.confirmPayPalPayment(
                            clientSecret,
                            {
                                payment_method: selectedPaymentMethod.id,
                                return_url: returnUrl,
                            }
                        )
                        break
                    }
                    case 'us_bank_account': {
                        result = await stripe.confirmUsBankAccountPayment(
                            clientSecret,
                            {
                                payment_method: selectedPaymentMethod.id,
                                return_url: returnUrl,
                            }
                        )
                        break
                    }
                    default: {
                        result = {
                            error: {
                                type: 'card_error',
                                message: 'Invalid payment method',
                            },
                        }
                    }
                }
            }
            if (!result.error) {
                store.dispatch(resetStripeOptions())
                if (selectedPaymentMethod) {
                    const url = new URL(returnUrl)
                    url.searchParams.append(
                        'payment_intent',
                        result.paymentIntent.id
                    )
                    window.location.href = url.toString()
                }
            }
            return result
        },
        []
    )

    return {
        addOnSubscriptions,
        paymentMethods,
        rootSubscription,
        rootSubscriptionEndDate,
        rootSubscriptionHumanReadableEndDate,
        seatSubscriptions,
        getPaymentMethodExpirationDate,
        simplifiedSeatSubscriptions,
        unresolvedSeatCount,
        confirmPayment,
        createPaymentMethod,
    }
}
