import {
    FC,
    Fragment,
    MouseEventHandler,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import {
    MapInsight,
    colors,
    InsightConfig,
    MapAreaLevel,
    Jurisdiction,
    getMapSpecificityLevel,
    websiteURL,
} from '@hazadapt-git/public-core-base'
import {
    AttributionControl,
    Layer,
    FillLayer,
    LineLayer,
    Map,
    MapRef,
    NavigationControl,
    Source,
    ViewState,
    ViewStateChangeEvent,
} from 'react-map-gl'
import mapboxgl, {
    Expression,
    MapboxEvent,
    MapLayerMouseEvent,
    MapLayerTouchEvent,
    MapWheelEvent,
    Popup,
} from 'mapbox-gl'
import Color from 'color'
import { makeStyles } from 'tss-react/mui'
import { Markdown } from '../atoms'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import NightShelterOutlinedIcon from '@mui/icons-material/NightShelterOutlined'
import HailIcon from '@mui/icons-material/Hail'
import {
    IoCheckmark,
    IoContract,
    IoEllipsisVertical,
    IoExpand,
    IoEyedrop,
} from 'react-icons/io5'
import classNames from 'classnames'
import { Box } from '@mui/system'
import { useWindowSizeUp } from '../../lib/utils'
import { prepCheckColor, theme } from '../../lib/styles/universal'
import tinycolor from 'tinycolor2'
import { MapThemePickerModal } from './MapThemePickerModal'
import bboxPolygon, { bbox } from '@turf/bbox'
import { ToggleButtons } from './ToggleButtons'
import {
    createJurisdictionMask,
    interactiveMapLayers,
} from '../../lib/utils/maps'
import { featureCollection } from '@turf/helpers'
import { BBox } from 'geojson'
import { getYear } from 'date-fns'

export interface MapInsightViewProps extends Omit<MapInsight, 'headline'> {
    boundary?: Jurisdiction
    headline: ReactNode
    defaultZoom?: number
    minZoom: number
    defaultCenter?: number[]
    defaultSelectedAreas?: string[]
    onUpdate(
        placement_id: string,
        bbox: BBox,
        zoom: number,
        visibleTiles: string[],
        selectedAreas?: Set<string>,
        requery?: boolean
    ): Promise<void>
    className?: string
    config: InsightConfig
    onSwitchVariant: (variant: string) => void | Promise<void>
    onSwitchColorTheme: (theme: string) => void
    onSwitchMode: (mode: string) => void | Promise<void>
}

export const MapInsightView: FC<MapInsightViewProps> = ({
    defaultZoom,
    minZoom,
    defaultCenter,
    defaultSelectedAreas = [],
    onSwitchColorTheme,
    onUpdate,
    className,
    config,
    onSwitchVariant,
    placement_id,
    onSwitchMode,
    boundary,
    ...data
}: MapInsightViewProps) => {
    const defaultZoomRef = useRef(defaultZoom)
    const defaultCenterRef = useRef(defaultCenter)
    const mapContainerRef = useRef<HTMLDivElement>(null)
    const mapRef = useRef<MapRef>(null)
    const selectedTilesRef = useRef<Set<string>>(new Set(defaultSelectedAreas))

    // Create a popup, but don't add it to the map yet.
    const popup = useRef<{
        elem: Popup
        feature_id: string | null
    }>({
        elem: new Popup({
            closeButton: false,
            closeOnClick: false,
        }),
        feature_id: null,
    })

    const { classes: localClasses } = useLocalStyles()
    const { variant, theme, mode } = config.pickers

    const [viewState, setViewState] = useState<Partial<ViewState>>({})
    const [themePickerOpen, setThemePickerOpen] = useState<boolean>(false)
    const [temporaryTheme, setTemporaryTheme] = useState<string>(
        theme?.selected_option ?? colors.secondary.GREENSHEEN
    )
    const [loading, setLoading] = useState<boolean>(false)
    const [mapExpanded, setMapExpanded] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [activeVariant, setActiveVariant] = useState<string>(
        variant?.selected_option ?? ''
    )
    const [showOverlay, setShowOverlay] = useState<boolean>(false)
    const lgScreens = useWindowSizeUp('lg')
    const mobileMenuOpen = Boolean(anchorEl)

    const handleMobileMenuOpen: MouseEventHandler<HTMLButtonElement> =
        useCallback((event) => {
            setAnchorEl(event.currentTarget)
        }, [])

    const handleMobileMenuClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleVariantChange = useCallback(
        async (selectedVariant: string) => {
            if (selectedVariant === activeVariant) return
            setActiveVariant(selectedVariant)
            setLoading(true)
            try {
                await onSwitchVariant(selectedVariant)
            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false)
            }
        },
        [activeVariant, onSwitchVariant]
    )

    const handleModeChange = useCallback(
        async (selectedMode: string) => {
            if (selectedMode === mode.selected_option) return
            setLoading(true)
            try {
                await onSwitchMode(selectedMode)
            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false)
            }
        },
        [onSwitchMode, mode?.selected_option]
    )

    const configurePopup = useCallback(
        (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
            if (
                !e.features ||
                e.features.length === 0 ||
                !['Polygon', 'MultiPolygon'].includes(
                    e.features?.[0].geometry.type
                )
            )
                return
            const layerInfo = data.map_config.find(
                (t) => t.id === e.features?.[0].id
            )
            if (!layerInfo) return
            // Change the cursor style as a UI indicator.
            e.target.getCanvas().style.cursor = 'pointer'

            const rawContent = `**${layerInfo.name}**\n\n${layerInfo.label}`
            const content = document.createElement('div')
            content.innerHTML = renderToStaticMarkup(
                <Markdown content={rawContent} />
            )

            // Populate the popup and set its coordinates on the user's mouse location.
            popup.current.elem.remove()
            popup.current.elem
                .setLngLat(e.lngLat)
                .setDOMContent(content)
                .addTo(e.target)
            popup.current.feature_id = layerInfo.id
        },
        [data]
    )

    const handleMouseMove = useCallback(
        (e: MapLayerMouseEvent) => {
            popup.current.elem.setLngLat(e.lngLat)

            if (
                !e.features ||
                e.features.length === 0 ||
                !['Polygon', 'MultiPolygon'].includes(
                    e.features?.[0].geometry.type
                )
            )
                return
            const layerInfo = data.map_config.find(
                (t) => t.id === e.features?.[0].id
            )
            if (!layerInfo) return
            if (popup.current.feature_id === layerInfo.id) return

            const rawContent = `**${layerInfo.name}**\n\n${layerInfo.label}`
            const content = document.createElement('div')
            content.innerHTML = renderToStaticMarkup(
                <Markdown content={rawContent} />
            )
            popup.current.elem.setDOMContent(content)
            popup.current.feature_id = layerInfo.id
        },
        [data]
    )

    const clearPopup = useCallback(
        (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
            e.target.getCanvas().style.cursor = ''
            popup.current.elem.remove()
            popup.current.feature_id = null
        },
        []
    )

    const fitMapToBounds = useCallback(
        (bbox: BBox, requery: boolean, firstRender = false) => {
            if (!mapRef.current) return
            mapRef.current.fitBounds(
                [
                    [bbox[0] - 0.25, bbox[1] - 0.25], // fit to bound with a little padding
                    [bbox[2] + 0.25, bbox[3] + 0.25], // fit to bound with a little padding
                ],
                undefined,
                { requery, firstRender }
            )
        },
        []
    )

    const updateMapContents = useCallback(
        async (map: mapboxgl.Map | MapRef, requery = false) => {
            if (loading) return
            const bounds = map.getBounds()
            const sw = bounds.getSouthWest()
            const ne = bounds.getNorthEast()
            const bbox = [sw.lng, sw.lat, ne.lng, ne.lat] as BBox
            const zoom = map.getZoom()
            setLoading(true)
            popup.current.elem.remove()
            popup.current.feature_id = null
            const tiles = map
                .queryRenderedFeatures(undefined, {
                    layers: interactiveMapLayers,
                })
                .filter(
                    (f) =>
                        !!f.id &&
                        f.id.toString().startsWith(getMapSpecificityLevel(zoom))
                )
                .map((f) => f.id?.toString()) as string[]
            try {
                await onUpdate(
                    placement_id,
                    bbox,
                    zoom,
                    tiles,
                    selectedTilesRef.current,
                    requery
                )
            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false)
            }
        },
        [onUpdate, placement_id, loading]
    )

    useEffect(() => {
        selectedTilesRef.current = new Set(defaultSelectedAreas)
    }, [defaultSelectedAreas])

    const handleMapMovement = useCallback(
        async (
            e: ViewStateChangeEvent & {
                requery?: boolean
                firstRender?: boolean
            }
        ) => {
            if (e.requery === false || e.firstRender || loading) return
            updateMapContents(e.target, e.requery)
        },
        [updateMapContents, loading]
    )

    const handleTileClick = useCallback(
        async (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
            if (!mapRef.current) return

            // Ensure features exist and are valid
            const features = e.features ?? []
            const firstFeature = features[0]

            if (
                !firstFeature ||
                !['Polygon', 'MultiPolygon'].includes(
                    firstFeature.geometry?.type
                )
            ) {
                if (selectedTilesRef.current.size === 0) {
                    // Clicking out when no tile is selected; do nothing
                    return
                }
                // Clicking out when a tile is selected
                selectedTilesRef.current.clear()
                // Safely check for tileName
                const tileName = firstFeature?.properties?.name as
                    | string
                    | undefined
                if (
                    tileName &&
                    Object.values(MapAreaLevel).some((l) =>
                        tileName.startsWith(l)
                    )
                ) {
                    selectedTilesRef.current.add(tileName)
                    const bbox = bboxPolygon(firstFeature)
                    fitMapToBounds(bbox, true)
                } else {
                    updateMapContents(mapRef.current)
                }
            } else {
                // Handle clicking on a valid tile
                const tileName = firstFeature?.properties?.name as
                    | string
                    | undefined
                if (!tileName || selectedTilesRef.current.has(tileName)) {
                    // Clicking on the tile that's already selected; do nothing
                    return
                }
                selectedTilesRef.current.clear()
                selectedTilesRef.current.add(tileName)
                const bbox = bboxPolygon(firstFeature)
                fitMapToBounds(bbox, true)
            }
        },
        [updateMapContents, fitMapToBounds]
    )

    const updateViewState = useCallback((e: ViewStateChangeEvent) => {
        setViewState(e.viewState)
    }, [])

    const observeResize = useCallback(
        (
            container: HTMLDivElement,
            previousDimensions: { height: number; width: number }
        ) => {
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    const newHeight = entry.contentRect.height
                    const newWidth = entry.contentRect.width

                    if (
                        newHeight > previousDimensions.height ||
                        newWidth > previousDimensions.width
                    ) {
                        previousDimensions.height = newHeight
                        previousDimensions.width = newWidth
                        if (mapRef.current) {
                            mapRef.current.resize()
                        }
                    }
                }
            })

            resizeObserver.observe(container)

            return () => {
                resizeObserver.unobserve(container)
            }
        },
        []
    )

    const expandOrCollapseMap = useCallback(() => {
        if (!mapContainerRef.current || !mapRef.current) return
        setMapExpanded((ex) => !ex)
    }, [])

    const handleMapLoad = useCallback(
        (e: MapboxEvent) => {
            // not sure this initial resize is 100% necessary, let's look into it when we do a performance audit
            e.target.resize()
            e.target.scrollZoom.disable()

            if (data.bbox) {
                if (!isFinite(data.bbox[0])) return
                fitMapToBounds(data.bbox, false, true)
            } else {
                const features = e.target.queryRenderedFeatures(undefined, {
                    layers: interactiveMapLayers,
                })
                const bounds: BBox = bbox(featureCollection(features))
                if (!isFinite(bounds[0])) return
                fitMapToBounds(bounds, false, true)
            }
        },
        [data.bbox, fitMapToBounds]
    )

    const handleWheel = useCallback((e: MapWheelEvent) => {
        let overlayTimeout: NodeJS.Timeout | undefined
        e.target.on('wheel', (e) => {
            if (e.originalEvent.ctrlKey) {
                setShowOverlay(false)
                e.target.scrollZoom.enable()
                clearTimeout(overlayTimeout)
            } else {
                e.preventDefault()
                setShowOverlay(true)
                clearTimeout(overlayTimeout)
                overlayTimeout = setTimeout(() => {
                    setShowOverlay(false)
                }, 250)
            }
        })
    }, [])

    const fillConfigurator = useCallback(
        (level: MapAreaLevel) => {
            const emptyColor = tinycolor(colors.grays.CUMULUS)
                .lighten(25)
                .setAlpha(0.67)
                .toRgbString()
            if (!data.map_config.some((tile) => tile.id.startsWith(level)))
                return emptyColor
            const matchExpression: Expression = ['match', ['get', 'id']]

            for (const tile of data.map_config) {
                if (!tile.id.startsWith(level)) continue
                const color =
                    tile.value > 0
                        ? tinycolor(temporaryTheme)
                              .lighten(tile.lightening)
                              .setAlpha(0.67)
                              .toRgbString()
                        : emptyColor
                matchExpression.push(tile.id, color)
            }
            matchExpression.push('rgba(0,0,0,0)')

            return matchExpression
        },
        [data, temporaryTheme]
    )

    const layerConfigurator = useCallback(
        (level: MapAreaLevel): Partial<FillLayer> | Partial<LineLayer> => {
            switch (level) {
                case MapAreaLevel.STATE: {
                    return {
                        source: 'areas',
                        'source-layer': 'states',
                        maxzoom: 5,
                        filter: [
                            'all',
                            ['==', 'level', level],
                            ['in', 'id', ...data.map_config.map((t) => t.id)],
                        ],
                    }
                }
                case MapAreaLevel.COUNTY: {
                    return {
                        source: 'areas',
                        'source-layer': 'counties',
                        minzoom: 5,
                        maxzoom: 9,
                        filter: [
                            'all',
                            ['==', 'level', level],
                            ['in', 'id', ...data.map_config.map((t) => t.id)],
                        ],
                    }
                }
                case MapAreaLevel.ZIP: {
                    return {
                        source: 'areas',
                        'source-layer': 'zips',
                        minzoom: 9,
                        filter: [
                            'all',
                            ['==', 'level', level],
                            ['in', 'id', ...data.map_config.map((t) => t.id)],
                        ],
                    }
                }
                default: {
                    return {
                        source: 'areas',
                        'source-layer': '',
                        minzoom: 0,
                        maxzoom: 0,
                        filter: [
                            'all',
                            ['==', 'level', level],
                            ['in', 'id', ...data.map_config.map((t) => t.id)],
                        ],
                    }
                }
            }
        },
        [data.map_config]
    )

    useEffect(() => {
        const newViewState: Partial<ViewState> = {}
        if (defaultCenterRef.current) {
            newViewState.longitude = defaultCenterRef.current[0]
            newViewState.latitude = defaultCenterRef.current[1]
        }
        if (defaultZoomRef.current !== undefined) {
            newViewState.zoom = defaultZoomRef.current
        }
        const resetViewState = () =>
            setViewState((vs) => ({
                ...vs,
                ...newViewState,
            }))

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.ctrlKey) {
                setShowOverlay(false)
            }
        }

        resetViewState()

        window.addEventListener('keydown', handleKeyDown)
        window.addEventListener('page-refresh', resetViewState)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
            window.removeEventListener('page-refresh', resetViewState)
        }
    }, [])

    useEffect(() => {
        if (!mapContainerRef.current) return

        const mapContainer = mapContainerRef.current

        const cleanupChildObserver = observeResize(mapContainer, {
            height: mapContainer.clientHeight,
            width: mapContainer.clientWidth,
        })
        return cleanupChildObserver
    }, [observeResize])

    const jurisdictionMask = useMemo(
        () => createJurisdictionMask(boundary),
        [boundary]
    )

    return (
        <>
            {/* MAP HEADER */}
            <div
                className={localClasses.header}
                style={{
                    backgroundColor: placement_id.includes('prep-check')
                        ? prepCheckColor
                        : '#1A638F',
                }}
            >
                <Stack
                    direction={lgScreens ? 'row' : 'column'}
                    gap={lgScreens ? '1rem' : '.5rem'}
                >
                    <Typography
                        variant="h4"
                        className={localClasses.headerText}
                        component="p"
                    >
                        {data.headline}
                    </Typography>
                    {/* MODE SELECTOR */}
                    {mode && (
                        <ToggleButtons
                            ariaLabel={`Toggle Between ${mode.options[0].label} and ${mode.options[1].label} Map Data`}
                            leftLabel={mode.options[0].label}
                            rightLabel={mode.options[1].label}
                            leftIcon={<NightShelterOutlinedIcon />}
                            rightIcon={<HailIcon />}
                            onLeftClick={() =>
                                handleModeChange(mode.options[0].value)
                            }
                            onRightClick={() =>
                                handleModeChange(mode.options[1].value)
                            }
                        />
                    )}
                </Stack>
                {/* EXPAND ICON */}
                <Tooltip
                    title={`${mapExpanded ? 'Shrink' : 'Expand'} map`}
                    placement="top"
                    arrow
                >
                    <IconButton
                        onClick={expandOrCollapseMap}
                        sx={{
                            padding: 0,
                            color: colors.grays.BLANC,
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {mapExpanded ? <IoContract /> : <IoExpand />}
                    </IconButton>
                </Tooltip>
            </div>

            <div
                className={classNames(localClasses.mapContainer, className, {
                    [localClasses.expandedMapContainer]: mapExpanded,
                })}
                ref={mapContainerRef}
            >
                {/* MAP CONTROLS */}
                <div className={localClasses.mapControls}>
                    {theme && theme.options.length > 0 && (
                        <Box
                            className={localClasses.iconContainer}
                            sx={{ borderBottomRightRadius: '0.5rem' }}
                        >
                            <IconButton
                                onClick={() => setThemePickerOpen(true)}
                            >
                                <IoEyedrop
                                    color={colors.grays.NOIR}
                                    size="1rem"
                                    aria-label="Open theme picker"
                                />
                            </IconButton>
                        </Box>
                    )}
                    {variant?.options.length > 0 && (
                        <>
                            {lgScreens ? (
                                <div className={localClasses.variantContainer}>
                                    {variant.options.map((o) => {
                                        const backgroundColor =
                                            o.buttonColor ??
                                            colors.primary.CERULEAN
                                        const borderColor =
                                            o.value === activeVariant
                                                ? Color(
                                                      o.buttonColor ??
                                                          colors.primary
                                                              .CERULEAN
                                                  )
                                                      .lighten(0.9)
                                                      .toString()
                                                : o.buttonColor ??
                                                  colors.primary.CERULEAN
                                        const hoverBackgroundColor = Color(
                                            o.buttonColor ??
                                                colors.primary.CERULEAN
                                        )
                                            .darken(0.1)
                                            .toString()
                                        const hoverBorderColor =
                                            o.value === activeVariant
                                                ? borderColor
                                                : hoverBackgroundColor
                                        return (
                                            <Button
                                                key={o.value}
                                                onClick={() =>
                                                    handleVariantChange(o.value)
                                                }
                                                className={
                                                    localClasses.variantOption
                                                }
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor:
                                                            hoverBackgroundColor,
                                                        borderColor:
                                                            hoverBorderColor,
                                                    },
                                                    backgroundColor:
                                                        backgroundColor,
                                                    borderColor: borderColor,
                                                }}
                                            >
                                                {o.label}
                                            </Button>
                                        )
                                    })}
                                </div>
                            ) : (
                                <Box
                                    className={localClasses.iconContainer}
                                    sx={{
                                        borderBottomLeftRadius: '0.5rem',
                                        marginLeft: 'auto',
                                    }}
                                >
                                    <IconButton onClick={handleMobileMenuOpen}>
                                        <IoEllipsisVertical
                                            color={colors.grays.NOIR}
                                            size="1rem"
                                            aria-label="Open map options menu"
                                        />
                                    </IconButton>
                                    <Menu
                                        id="map-insight-menu"
                                        anchorEl={anchorEl}
                                        open={mobileMenuOpen}
                                        onClose={handleMobileMenuClose}
                                    >
                                        {variant.options.map((o) => (
                                            <MenuItem
                                                key={o.value}
                                                onClick={() =>
                                                    handleVariantChange(o.value)
                                                }
                                            >
                                                {o.value === activeVariant ? (
                                                    <>
                                                        <ListItemIcon>
                                                            <IoCheckmark />
                                                        </ListItemIcon>
                                                        <span>{o.label}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <ListItemText inset>
                                                            {o.label}
                                                        </ListItemText>
                                                    </>
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            )}
                        </>
                    )}
                </div>

                {/* MAP */}
                <Map
                    {...viewState}
                    ref={mapRef}
                    mapLib={import('mapbox-gl')}
                    projection={{ name: 'mercator' }}
                    mapStyle="mapbox://styles/mapbox/light-v11"
                    style={{ height: '100%' }}
                    onLoad={handleMapLoad}
                    onMove={updateViewState}
                    interactiveLayerIds={interactiveMapLayers}
                    minZoom={minZoom}
                    onClick={handleTileClick}
                    onMoveEnd={handleMapMovement}
                    reuseMaps
                    onMouseEnter={configurePopup}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={clearPopup}
                    onTouchEnd={configurePopup}
                    onTouchCancel={clearPopup}
                    onWheel={handleWheel}
                    touchPitch={false}
                    pitchWithRotate={false}
                    attributionControl={false}
                    pitch={0}
                >
                    <NavigationControl
                        style={{
                            marginTop:
                                lgScreens ||
                                !variant ||
                                variant.options.length === 0
                                    ? '0.5625rem'
                                    : '3.75rem',
                        }}
                    />
                    <AttributionControl
                        position="bottom-right"
                        customAttribution={`&copy; ${getYear(
                            Date.now()
                        )} <a target="_blank" href=${websiteURL}>HazAdapt, Inc.</a>`}
                    />
                    {jurisdictionMask && (
                        <Source
                            id="jurisdiction"
                            type="geojson"
                            data={jurisdictionMask}
                        >
                            <Layer
                                id="jurisdiction-mask"
                                type="fill"
                                source="jurisdiction"
                                paint={{
                                    'fill-color': colors.grays.BLANC,
                                    'fill-opacity': 0.999,
                                }}
                            />
                        </Source>
                    )}
                    <Source
                        id="areas"
                        type="vector"
                        url={`mapbox://${
                            process.env.REACT_APP_MAPBOX_AREAS_TILSET_ID ?? ''
                        }`}
                        promoteId="id"
                    >
                        {Object.values(MapAreaLevel).map((l) => (
                            <Fragment key={l}>
                                <Layer
                                    id={`${l}-fill`}
                                    type="fill"
                                    {...(layerConfigurator(
                                        l
                                    ) as Partial<FillLayer>)}
                                    paint={{
                                        'fill-color': fillConfigurator(l),
                                    }}
                                />
                                <Layer
                                    id={`${l}-line`}
                                    type="line"
                                    {...(layerConfigurator(
                                        l
                                    ) as Partial<LineLayer>)}
                                    paint={{
                                        'line-color': colors.grays.NIMBUS,
                                        'line-width': 1,
                                    }}
                                />
                            </Fragment>
                        ))}
                    </Source>
                </Map>

                {/* LOADING AND HOTKEY OVERLAYS */}
                {loading || showOverlay ? (
                    <div
                        className={localClasses.overlayContainer}
                        style={
                            showOverlay ? { pointerEvents: 'none' } : undefined
                        }
                    >
                        <div className={localClasses.overlayContent}>
                            {loading && (
                                <CircularProgress size="1rem" color="primary" />
                            )}
                            <Typography fontWeight={500}>
                                {loading
                                    ? 'Updating...'
                                    : "Hold 'Control' and scroll to zoom"}
                            </Typography>
                        </div>
                    </div>
                ) : null}
            </div>
            <MapThemePickerModal
                open={themePickerOpen}
                onClose={() => setThemePickerOpen(false)}
                themeConfig={theme}
                selectedTheme={temporaryTheme}
                setTheme={setTemporaryTheme}
                onSaveThemeChange={() => onSwitchColorTheme(temporaryTheme)}
            />
        </>
    )
}

const useLocalStyles = makeStyles()({
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem',
    },
    headerText: {
        alignItems: 'center',
        color: colors.grays.BLANC,
        display: 'flex',
        flexWrap: 'wrap',
        whiteSpace: 'break-spaces',
    },
    mapContainer: {
        height: '15rem',
        position: 'relative',
        transition: 'all .3s ease-in-out',
        [theme.breakpoints.up('md')]: {
            height: '20rem',
        },
        [theme.breakpoints.up('xl')]: {
            height: '25rem',
        },
    },
    expandedMapContainer: {
        height: '30rem',
        [theme.breakpoints.up('md')]: {
            height: '40rem',
        },
        [theme.breakpoints.up('xl')]: {
            height: '50rem',
        },
    },
    map: {
        height: '100%',
    },
    mapControls: {
        alignItems: 'flex-start',
        display: 'flex',
        pointerEvents: 'none',
        position: 'absolute',
        width: '100%',
        zIndex: 1,
    },
    iconContainer: {
        backgroundColor: colors.grays.BLANC,
        boxShadow: '0 0.25rem 0.5rem rgba(0,0,0,0.16)',
        padding: '.5rem',
        pointerEvents: 'all',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
    variantContainer: {
        display: 'flex',
        gap: '.5rem',
        marginLeft: 'auto',
        marginRight: '2.625rem',
        padding: '.5rem',
    },
    variantOption: {
        borderRadius: '.5rem',
        borderStyle: 'solid',
        borderWidth: 3,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        minWidth: '10rem',
        padding: '.5rem',
        pointerEvents: 'all',
    },
    overlayContainer: {
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 2,
    },
    overlayContent: {
        alignItems: 'center',
        backgroundColor: colors.grays.BLANC,
        borderRadius: '0.5rem',
        display: 'flex',
        gap: '1rem',
        justifyContent: 'center',
        padding: '1rem',
    },
})
