import { TZDate } from '@date-fns/tz'
import {
    Insight,
    InsightQueryBase,
    InsightQueryDTO,
    InsightQueryType,
    SurveyAnswerInsight,
} from '@hazadapt-git/public-core-base'
import React, { FC, useCallback, useMemo, useRef } from 'react'

import { QueryBuilderValues } from '../components'
import { NamespacedPageProps } from '../lib/entities'
import {
    saveQuery,
    exportInsights,
    persistLocalPickerChangesOnNewConfig,
    getSingleInsight,
    buildQueryDateRange,
    refreshSurveyInsight,
} from '../lib/utils/insights'
import { useAppSelector } from '../lib/store'
import { getHazardData, getPrepCheckData } from '../lib/utils/cms'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { SurveyAnswerInsightTemplate } from '../components/templates/SurveyAnswerInsightTemplate'
import { shallowEqual } from 'react-redux'
import { toISOStringWithTimezone } from '../lib/utils'

interface SurveyAnswerInsightProps extends NamespacedPageProps {}

export const SurveyAnswerInsights: FC<SurveyAnswerInsightProps> = ({
    user,
    organization,
    loading,
}) => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [activeQuery, setActiveQuery] = React.useState<InsightQueryDTO>()
    const [retrievingInsights, setRetrievingInsights] =
        React.useState<boolean>(true)
    const [surveyInsight, setSurveyInsight] =
        React.useState<SurveyAnswerInsight>()
    const prepChecks = useAppSelector(
        (state) => state.content.prepChecks,
        shallowEqual
    )

    const href = useMemo(() => {
        const pieces = location.pathname.split('/')
        return pieces[pieces.length - 1] + location.search
    }, [location.pathname, location.search])

    const hasMounted = useRef(false)

    const setSurvey = React.useCallback((insight: Insight) => {
        if (insight && insight.type === 'survey-answer') {
            setSurveyInsight(insight)
        }
        setRetrievingInsights(false)
    }, [])

    const refreshInsights = React.useCallback(
        async (
            existingQuery: InsightQueryDTO,
            reset = false,
            updatedParams = false
        ) => {
            setRetrievingInsights(true)
            getSingleInsight({
                ...existingQuery,
                type: InsightQueryType.PREP_CHECK_QUESTION,
                start_date:
                    existingQuery.id > 0 || updatedParams
                        ? existingQuery.start_date
                        : undefined,
                end_date:
                    existingQuery.id > 0 || updatedParams
                        ? existingQuery.end_date
                        : undefined,
                reset,
                href,
            })
                .then((res) => {
                    const { insights, quick_stat_row_title, ...newQuery } = res
                    persistLocalPickerChangesOnNewConfig(
                        newQuery.grid_config,
                        existingQuery.grid_config
                    )
                    setActiveQuery(newQuery)
                    setSurvey(insights[0])
                })
                .catch(console.error)
                .finally(() => setRetrievingInsights(false))
        },
        [href, setSurvey]
    )

    const fetchData = React.useCallback(() => {
        setRetrievingInsights(true)
        getSingleInsight({
            type: InsightQueryType.PREP_CHECK_QUESTION,
            searchParams,
            href,
            recenter_map: true,
        })
            .then((res) => {
                const { insights, ...query } = res
                setActiveQuery(query)
                setSurveyInsight(
                    insights[0].type === 'survey-answer'
                        ? insights[0]
                        : undefined
                )
            })
            .catch(console.error)
            .finally(() => setRetrievingInsights(false))
    }, [searchParams, href])

    React.useEffect(() => {
        if (!hasMounted.current) {
            fetchData()
            hasMounted.current = true
        }
    }, [fetchData])

    React.useEffect(() => {
        getHazardData()
        getPrepCheckData()
    }, [organization])

    const onSaveQuery = useCallback(async () => {
        if (!activeQuery) return
        saveQuery({
            ...activeQuery,
            type: InsightQueryType.PREP_CHECK_QUESTION,
            insight_ids: [surveyInsight?.placement_id ?? ''],
        })
    }, [activeQuery, surveyInsight])

    const onRunQueryPress = React.useCallback(
        (values: QueryBuilderValues) =>
            new Promise<void>((resolve, reject) => {
                if (!activeQuery || !user) {
                    reject('No query to run, or no user')
                    return
                }
                const pickerTwoValues: string[] = Array.isArray(
                    values.pickerTwoValue
                )
                    ? values.pickerTwoValue
                    : [values.pickerTwoValue]
                const fullJurisdiction =
                    pickerTwoValues.length === 1 &&
                    pickerTwoValues[0] === 'jurisidction'
                const active_query: InsightQueryDTO = {
                    ...activeQuery,
                    prep_checks: values.pickerOneValue
                        ? [parseInt(values.pickerOneValue.toString())]
                        : [],
                    zips: fullJurisdiction
                        ? []
                        : pickerTwoValues.filter((v) =>
                              organization?.areas.zip.includes(v)
                          ),
                    counties: fullJurisdiction
                        ? []
                        : pickerTwoValues.filter((v) =>
                              organization?.areas.county.includes(v)
                          ),
                    states: fullJurisdiction
                        ? []
                        : pickerTwoValues.filter((v) =>
                              organization?.areas.state.includes(v)
                          ),
                    start_date: values.start_date ?? undefined,
                    end_date: values.end_date ?? undefined,
                }

                const newSearchParams = new URLSearchParams(location.search)
                if (values.pickerOneValue) {
                    newSearchParams.set(
                        'prep_checks',
                        values.pickerOneValue.toString()
                    )
                }
                if (values.pickerTwoValue) {
                    newSearchParams.set(
                        'zips',
                        values.pickerTwoValue.toString()
                    )
                }
                if (values.start_date) {
                    newSearchParams.set(
                        'start_date',
                        toISOStringWithTimezone(new TZDate(values.start_date))
                    )
                }
                if (values.end_date) {
                    newSearchParams.set(
                        'end_date',
                        toISOStringWithTimezone(new TZDate(values.end_date))
                    )
                }
                refreshInsights(active_query, undefined, true)
                    .then(() => {
                        setActiveQuery(active_query)
                        navigate(
                            `${location.pathname}?${newSearchParams.toString()}`
                        )
                    })
                    .then(resolve)
                    .catch(reject)
            }),
        [
            activeQuery,
            user,
            location.search,
            location.pathname,
            refreshInsights,
            navigate,
            organization,
        ]
    )

    const updateSingleInsight = React.useCallback(
        async (placement_id: string, args: InsightQueryBase) => {
            setRetrievingInsights(true)
            setActiveQuery((aq) =>
                aq
                    ? {
                          ...aq,
                          ...args,
                      }
                    : undefined
            )
            const newInsight = await refreshSurveyInsight(
                placement_id,
                InsightQueryType.PREP_CHECK_QUESTION,
                args
            )
            setSurveyInsight(newInsight)
            setRetrievingInsights(false)
        },
        []
    )

    const onReloadPress = React.useCallback(() => {
        if (!activeQuery) return
        refreshInsights(activeQuery, undefined, true)
    }, [activeQuery, refreshInsights])

    const onResetQueryPress = React.useCallback(() => {
        navigate('/insights/prep-checks')
    }, [navigate])

    // Resets query when user clicks link to page they are currently on
    React.useEffect(() => {
        window.addEventListener('page-refresh', () => onResetQueryPress())

        return () => {
            window.removeEventListener('page-refresh', () =>
                onResetQueryPress()
            )
        }
    }, [onResetQueryPress])

    const prepCheckOptions = useMemo(
        () =>
            [...prepChecks]
                .sort((a, b) => (a.title < b.title ? -1 : 1))
                .map((pc) => ({ label: pc.title, value: pc.id })),
        [prepChecks]
    )

    const onNextQuestionPress = () => {
        if (surveyInsight?.next && activeQuery) {
            updateSingleInsight(surveyInsight.next.split('|')[0], activeQuery)
        }
    }

    const onPrevQuestionPress = () => {
        if (surveyInsight?.prev && activeQuery) {
            updateSingleInsight(surveyInsight.prev.split('|')[0], activeQuery)
        }
    }

    const onBack = () => {
        const prepChecks = activeQuery?.prep_checks
        const startDate = activeQuery?.start_date
        const endDate = activeQuery?.end_date
        const zips = activeQuery?.zips
        const newUrl = `/insights/prep-checks?prep_checks=${prepChecks}&zips=${zips}&start_date=${startDate}&end_date=${endDate}`
        navigate(newUrl)
    }

    return organization && user ? (
        <SurveyAnswerInsightTemplate
            organization={organization}
            queryItems={prepCheckOptions}
            selectedQueryItem={activeQuery?.prep_checks?.[0]}
            selectedAreas={[
                ...(activeQuery?.zips ?? []),
                ...(activeQuery?.counties ?? []),
                ...(activeQuery?.states ?? []),
            ]}
            startDate={
                activeQuery?.start_date
                    ? new Date(activeQuery.start_date)
                    : null
            }
            queryId={activeQuery?.id}
            endDate={
                activeQuery?.end_date ? new Date(activeQuery.end_date) : null
            }
            onReloadPress={onReloadPress}
            updatedDate={
                activeQuery?.date_last_used
                    ? new Date(activeQuery.date_last_used)
                    : undefined
            }
            onRunQueryPress={onRunQueryPress}
            onResetQueryPress={onResetQueryPress}
            onSaveQueryPress={onSaveQuery}
            onDownloadPress={() =>
                exportInsights(
                    InsightQueryType.PREP_CHECKS,
                    organization.slug,
                    activeQuery,
                    undefined,
                    [href.split('?')[0]]
                )
            }
            onNextQuestionPress={onNextQuestionPress}
            onPrevQuestionPress={onPrevQuestionPress}
            onBack={onBack}
            surveyData={surveyInsight}
            selectedLocationString={
                activeQuery?.zips?.join(', ') || 'Full Jurisdiction'
            }
            selectedDatesString={buildQueryDateRange(activeQuery)}
            minQueryDate={new Date(organization.date_created)}
            loading={loading || retrievingInsights}
        />
    ) : null
}
