import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import {
    DEFAULT_ANIMATION_SPEED,
    MenuItemProps,
    NamespacedPageProps,
    RPPaymentMethod,
} from '../../lib/entities'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
    primaryIconSize,
    successColor,
    theme,
} from '../../lib/styles/universal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    IoCheckmarkCircle,
    IoOpenOutline,
    IoStar,
    IoTrashOutline,
} from 'react-icons/io5'
import { colors, SubscriptionStatus } from '@hazadapt-git/public-core-base'
import classNames from 'classnames'
import { useAppSelector } from '../../lib/store'
import {
    deletePaymentMethod,
    getHazardData,
    restoreSubscription,
    toast,
    updateDefaultPaymentMethod,
    useWindowSizeUp,
} from '../../lib/utils'
import { useBilling } from '../../lib/hooks/useBilling'
import { AddSeatsTriggerAndDrawer } from './AddSeatsTriggerAndDrawer'
import {
    ChangePaymentMethodModal,
    DeletePaymentMethodModal,
    NewPaymentMethodButton,
    PaymentMethodDetails,
    RestoreCoreSubscriptionModal,
} from '../molecules'
import { shallowEqual } from 'react-redux'
import { useAutoAnimate } from '@formkit/auto-animate/react'

interface SubscriptionsOverviewProps extends NamespacedPageProps {}

export const SubscriptionsOverview: FC<SubscriptionsOverviewProps> = ({
    organization,
}) => {
    const searchParamsParsed = useRef(false)
    const { classes: localClasses } = useLocalStyles()
    const containerRef = useRef(null)
    const lgElement = useWindowSizeUp('lg')
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const hazards = useAppSelector(
        (state) => state.content.hazards,
        shallowEqual
    )
    const {
        rootSubscription,
        rootSubscriptionEndDate,
        rootSubscriptionHumanReadableEndDate,
        simplifiedSeatSubscriptions,
        paymentMethods,
    } = useBilling()
    const [
        showConfirmDeletePaymentMethodModal,
        setShowConfirmDeletePaymentMethodModal,
    ] = useState(false)
    const [paymentMethodToDelete, setPaymentMethodToDelete] =
        useState<RPPaymentMethod>()
    const [paymentMethodToMakeDefault, setPaymentMethodToMakeDefault] =
        useState<RPPaymentMethod>()
    const [
        showPickNewDefaultPaymentMethodModal,
        setShowPickNewDefaultPaymentMethodModal,
    ] = useState(false)
    const [
        showRestoreCoreSubscriptionModal,
        setShowRestoreCoreSubscriptionModal,
    ] = useState(false)

    const [paymentMethodListAnimation] = useAutoAnimate({
        duration: DEFAULT_ANIMATION_SPEED,
        easing: 'ease-in-out',
    })

    useEffect(() => {
        if (searchParamsParsed.current) return
        searchParamsParsed.current = true
        const setupIntent = searchParams.get('setup_intent')
        const setupIntentClientSecret = searchParams.get(
            'setup_intent_client_secret'
        )
        const redirectStatus = searchParams.get('redirect_status')
        if (
            !!setupIntent &&
            !!setupIntentClientSecret &&
            redirectStatus === 'succeeded'
        ) {
            // Payment method was successfully generated and redirected here
            toast(
                'Saved payment method!',
                <IoCheckmarkCircle
                    color={successColor}
                    size={primaryIconSize}
                />
            )
        }

        // Clear setup intent params from URL
        setSearchParams((prev) => {
            prev.delete('setup_intent')
            prev.delete('setup_intent_client_secret')
            prev.delete('redirect_status')
            return prev
        })
    }, [searchParams, setSearchParams])

    useEffect(() => {
        getHazardData()
    }, [organization])

    const visibleHazards = useMemo(() => {
        return hazards
            .filter((haz) => organization?.visible_hazards.includes(haz.id))
            .sort((a, b) => a.name.localeCompare(b.name))
    }, [hazards, organization])

    const activeSeatSubscriptions = useMemo(
        () =>
            simplifiedSeatSubscriptions?.filter(
                (sub) => sub.status !== SubscriptionStatus.CANCELLED
            ),
        [simplifiedSeatSubscriptions]
    )

    const onDeletePaymentMethodClick = useCallback(
        (e: React.MouseEvent, method: RPPaymentMethod) => {
            setShowConfirmDeletePaymentMethodModal(true)
            setPaymentMethodToDelete(method)
        },
        []
    )

    const onUpdateDefaultPaymentMethodClick = useCallback(
        (e: React.MouseEvent, method: RPPaymentMethod) => {
            // TODO: Do we need a confirmation modal here? Do we want this action to trigger an email alert?
            updateDefaultPaymentMethod(method)
        },
        []
    )

    const closeDeletePaymentMethodModal = useCallback(() => {
        setShowConfirmDeletePaymentMethodModal(false)
        setShowPickNewDefaultPaymentMethodModal(false)
        setPaymentMethodToDelete(undefined)
        setPaymentMethodToMakeDefault(undefined)
    }, [])

    const confirmDeletePaymentMethod: React.MouseEventHandler =
        useCallback(async () => {
            if (!paymentMethodToDelete) return
            if (
                paymentMethodToDelete.default &&
                (!paymentMethodToMakeDefault ||
                    paymentMethodToMakeDefault?.id === paymentMethodToDelete.id)
            ) {
                // PM to delete is the default; need to pick a new default before continuing
                setShowPickNewDefaultPaymentMethodModal(true)
                return
            }

            if (paymentMethodToMakeDefault) {
                await updateDefaultPaymentMethod(paymentMethodToMakeDefault)
            }

            closeDeletePaymentMethodModal()
            await deletePaymentMethod(paymentMethodToDelete)
        }, [
            closeDeletePaymentMethodModal,
            paymentMethodToDelete,
            paymentMethodToMakeDefault,
        ])

    const handleAddNewPaymentMethod: React.MouseEventHandler = useCallback(
        (e) => {
            navigate('/settings/subscriptions/payment-methods/new')
        },
        [navigate]
    )

    const handleCoreSubscriptionChange: React.MouseEventHandler =
        useCallback(() => {
            if (!rootSubscription) return
            switch (rootSubscription.status) {
                case SubscriptionStatus.ACTIVE:
                    navigate('core/cancel')
                    break
                case SubscriptionStatus.PENDING_CANCELLATION:
                    setShowRestoreCoreSubscriptionModal(true)
                    break
                default:
                    break
            }
        }, [rootSubscription, navigate])

    const closeRestoreCoreSubscriptionModal = () =>
        setShowRestoreCoreSubscriptionModal(false)

    const confirmRestoreCoreSubscription = useCallback(() => {
        if (!rootSubscription) return
        setShowRestoreCoreSubscriptionModal(false)
        restoreSubscription(rootSubscription.id)
    }, [rootSubscription])

    return (
        <>
            <Stack gap={'2rem'} paddingBottom={'4rem'} ref={containerRef}>
                <Stack gap={'.5rem'} maxWidth={'34rem'}>
                    <Typography variant="body1" fontWeight={500}>
                        Make changes to your subscriptions or add seats here.
                    </Typography>
                    <Typography variant="body2" color={colors.grays.CHARCOAL}>
                        Please note if you would like to make changes to a
                        different organization, you must be logged in to the
                        organization.
                    </Typography>
                </Stack>
                {/* Page Description */}
                <Stack gap={'2rem'} maxWidth={'43rem'}>
                    <Typography
                        variant={lgElement ? 'h2' : 'h3'}
                        component="h1"
                    >
                        Subscriptions/Billing
                    </Typography>
                    <Typography variant="body2" color={colors.grays.CHARCOAL}>
                        Please allow up to 24 hours for changes to your
                        ResiliencePoint subscription to take effect. Changes to
                        your subscription do not affect any extra seats that
                        have been purchased. To make changes to any seats
                        outside of the subscription, please do so from the{' '}
                        <Link href="subscriptions/seats" target="_blank">
                            Seats Page <IoOpenOutline />
                        </Link>
                    </Typography>
                </Stack>

                {/* Payment Method */}
                <Stack gap={'1rem'}>
                    <Typography variant="h4">Saved Payment Methods</Typography>
                    <div className={localClasses.paymentMethodsWrapper}>
                        <div
                            className={localClasses.paymentMethods}
                            ref={paymentMethodListAnimation}
                        >
                            {paymentMethods.length > 0 ? (
                                paymentMethods.map((pm) => {
                                    const actions: MenuItemProps[] = [
                                        {
                                            label: 'Delete Payment Method',
                                            icon: (
                                                <IoTrashOutline
                                                    size={primaryIconSize}
                                                />
                                            ),
                                            onClick: (e) =>
                                                onDeletePaymentMethodClick(
                                                    e,
                                                    pm
                                                ),
                                        },
                                    ]
                                    if (!pm.default) {
                                        actions.splice(0, 0, {
                                            label: 'Make Default Payment Method',
                                            icon: (
                                                <IoStar
                                                    size={primaryIconSize}
                                                />
                                            ),
                                            onClick: (e) =>
                                                onUpdateDefaultPaymentMethodClick(
                                                    e,
                                                    pm
                                                ),
                                        })
                                    }
                                    return (
                                        <PaymentMethodDetails
                                            {...pm}
                                            key={pm.id}
                                            actions={actions}
                                            className={
                                                localClasses.paymentMethod
                                            }
                                        />
                                    )
                                })
                            ) : (
                                <Typography variant="subtitle2">
                                    No saved payment method
                                </Typography>
                            )}
                            <NewPaymentMethodButton
                                onClick={handleAddNewPaymentMethod}
                                className={localClasses.paymentMethod}
                            />
                        </div>
                    </div>
                </Stack>

                {/* Subscriptions */}
                <Stack gap={'1rem'}>
                    <Typography variant="h4">Current Subscriptions</Typography>
                    <Stack
                        direction={lgElement ? 'row' : 'column'}
                        gap={'1rem'}
                    >
                        {/* Root Subscription */}
                        <Stack
                            className={classNames(
                                localClasses.borderedContainer,
                                localClasses.subscriptionItemContainer
                            )}
                        >
                            <div
                                className={localClasses.renewalDate}
                                style={{
                                    borderColor:
                                        rootSubscription?.metadata.status ===
                                        'active'
                                            ? colors.primary.CERULEAN
                                            : colors.secondary.HEAT_WAVE,
                                }}
                            >
                                <Typography variant="body2">
                                    {rootSubscription?.metadata.status ===
                                    'active'
                                        ? 'Renews'
                                        : 'Expires'}{' '}
                                    On: {rootSubscriptionEndDate}
                                </Typography>
                            </div>
                            <Typography
                                variant={lgElement ? 'h1' : 'h2'}
                                paddingBottom={'1rem'}
                            >
                                ResiliencePoint
                            </Typography>
                            <Typography variant="h4">
                                Hazard Guide Insights:
                            </Typography>
                            <div className={localClasses.subscriptionItemList}>
                                {visibleHazards.length === 0 ? (
                                    <Typography
                                        variant={lgElement ? 'h4' : 'body1'}
                                        fontWeight={500}
                                    >
                                        (All Hazards)
                                    </Typography>
                                ) : (
                                    visibleHazards.map((hazard) => (
                                        <Typography
                                            key={hazard.id}
                                            variant={lgElement ? 'h4' : 'body1'}
                                            fontWeight={500}
                                        >
                                            {hazard.name}
                                        </Typography>
                                    ))
                                )}
                            </div>
                            {/* //TODO */}
                            {/* <Typography variant="h4">
                                Local Safety Information
                            </Typography> */}
                            {rootSubscription &&
                                rootSubscription?.status !==
                                    SubscriptionStatus.CANCELLED && (
                                    <Button
                                        variant="outlined"
                                        onClick={handleCoreSubscriptionChange}
                                        color={
                                            rootSubscription.status ===
                                            SubscriptionStatus.ACTIVE
                                                ? 'error'
                                                : 'primary'
                                        }
                                        sx={{ alignSelf: 'end' }}
                                    >
                                        {rootSubscription.status ===
                                        SubscriptionStatus.PENDING_CANCELLATION
                                            ? 'Restore Subscription'
                                            : 'Cancel Subscription'}
                                    </Button>
                                )}
                        </Stack>
                        {/* Add-On Subscriptions */}
                        <Stack
                            className={classNames(
                                localClasses.borderedContainer,
                                localClasses.subscriptionItemContainer
                            )}
                        >
                            <Typography
                                variant={lgElement ? 'h1' : 'h2'}
                                paddingTop={'2.1rem'}
                            >
                                Add-Ons
                            </Typography>
                            {/* Seat Subscriptions */}
                            <div className={localClasses.subscriptionItemList}>
                                {activeSeatSubscriptions.length > 0 ? (
                                    <ul style={{ margin: 0 }}>
                                        {activeSeatSubscriptions.map((sub) => (
                                            <li key={sub.id}>
                                                <Typography
                                                    variant={
                                                        lgElement
                                                            ? 'h4'
                                                            : 'body1'
                                                    }
                                                    fontWeight={500}
                                                >
                                                    {sub.seatCount} seat
                                                    {sub.seatCount === 1
                                                        ? ''
                                                        : 's'}{' '}
                                                    - Renewal: {sub.renewalDate}
                                                </Typography>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <Typography variant="h4">
                                        No Add-Ons to display
                                    </Typography>
                                )}
                            </div>
                            <div style={{ alignSelf: 'end' }}>
                                {activeSeatSubscriptions.length === 0 ? (
                                    <AddSeatsTriggerAndDrawer buttonText="Add Seats" />
                                ) : (
                                    <Button onClick={() => navigate('seats')}>
                                        Manage
                                    </Button>
                                )}
                            </div>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <DeletePaymentMethodModal
                open={
                    showConfirmDeletePaymentMethodModal &&
                    !showPickNewDefaultPaymentMethodModal &&
                    !!paymentMethodToDelete
                }
                paymentMethod={paymentMethodToDelete}
                onCancel={closeDeletePaymentMethodModal}
                onConfirmDelete={confirmDeletePaymentMethod}
            />
            <ChangePaymentMethodModal
                open={showPickNewDefaultPaymentMethodModal}
                onCancel={closeDeletePaymentMethodModal}
                onSubmit={confirmDeletePaymentMethod}
                title="Pick a New Default Payment Method"
                subcopy="You're about to delete your organization's default payment method! Please select a new default payment method before continuing."
                methods={paymentMethods.filter(
                    (pm) => pm.id !== paymentMethodToDelete?.id
                )}
                selectedMethod={paymentMethodToMakeDefault}
                onSelect={setPaymentMethodToMakeDefault}
            />
            <RestoreCoreSubscriptionModal
                open={showRestoreCoreSubscriptionModal}
                onCancel={closeRestoreCoreSubscriptionModal}
                onConfirm={confirmRestoreCoreSubscription}
                expirationDate={rootSubscriptionHumanReadableEndDate}
            />
        </>
    )
}

const useLocalStyles = makeStyles()({
    borderedContainer: {
        backgroundColor: colors.grays.BLANC,
        border: '1px solid',
        borderRadius: '.625rem',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
    },
    paymentMethodsWrapper: {
        alignItems: 'center',
    },
    paymentMethods: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.up('xl')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        gap: '1rem',
    },
    paymentMethod: {
        padding: '1rem 2rem',
        paddingRight: '3rem',
        borderRadius: '0.5rem',
        border: '1px solid',
        position: 'relative',
        backgroundColor: colors.grays.BLANC,
    },
    addNewPaymentMethod: {
        paddingRight: '2rem !important',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.grays.SILK,
        textAlign: 'center',
    },
    subscriptionItemContainer: {
        flex: 1,
        justifyContent: 'space-between',
        padding: '1.5rem',
        position: 'relative',
    },
    subscriptionItemList: {
        height: '4rem',
        overflow: 'auto',
        margin: '.5rem 0',
    },
    renewalDate: {
        alignSelf: 'end',
        border: '3px solid transparent',
        borderRadius: '.625rem',
        padding: '.5rem',
        width: 'auto',
    },
})
